import React from 'react';
import Link from '../link';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

export default function BackToAllPostsLink() {
    const { t } = useI18next();

    return (
        <Link to="/blog" textUppercase type="filled">
            {t('posts-section.all-posts')}
        </Link>
    );
}
