import React from 'react';

import { IPostBrief } from '../../../models/post.model';
import { IAuthor } from '../../../models/author.model';
import {
    promotedPostsSection,
    buttonContainer,
    blogList,
    title,
    blogItem,
} from './posts-section.module.scss';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import Section from '../../molecules/section';
import Title from '../../atoms/title';
import BlogList from '../blog/blog-list';
import SectionWrapper from '../../molecules/section-wrapper';
import BackToAllPostsLink from "../../atoms/blog/back-to-all-posts-link";

export interface PromotedPostsSectionProps {
    posts: IPostBrief[];
    authors: IAuthor[];
    titleKey: string;
    subtitleKey: string;
}

export default function PostsSection({
    posts,
    authors,
    titleKey,
    subtitleKey,
}: PromotedPostsSectionProps) {
    const { t } = useI18next();

    return (
        <SectionWrapper className={promotedPostsSection}>
            <Section>
                <Title
                    subtitle={t(subtitleKey)}
                    align="center"
                    className={title}
                    size="medium"
                >
                    {t(titleKey)}
                </Title>
            </Section>
            <Section>
                <BlogList
                    posts={posts}
                    authors={authors}
                    listClassName={blogList}
                    itemClassName={blogItem}
                />
            </Section>
            <Section className={buttonContainer}>
                <BackToAllPostsLink />
            </Section>
        </SectionWrapper>
    );
}
