import { IPostBrief } from '../models/post.model';
import { IAuthor } from '../models/author.model';

export default function getPostsWithAuthor(
    posts: IPostBrief[],
    authors: IAuthor[]
): IPostBrief[] {
    return posts.map((post) => {
        const author = authors.find(
            (author) => author.profileId === post.author.profileId
        );
        return {
            ...post,
            author: author ? author : post.author,
        };
    });
}
