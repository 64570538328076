import { RefObject, useEffect, useState } from "react";

// tytuł (element z fontSize) musi posiadać width: fit-content

const useFontSizeFit = (
    textElementRef: RefObject<HTMLElement> | undefined,
    containerRef: RefObject<HTMLElement> | undefined,
): number | undefined => {

    const [fontSize, setFontSize] = useState<number | undefined>();
    const [isFontFitting, setIsFontFitting] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setFontSize(undefined);
            setIsFontFitting(false);
        }

        if (!containerRef?.current || !textElementRef?.current) return;

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [containerRef]);

    useEffect(() => {
        if (isFontFitting) return;
        if (!containerRef?.current || !textElementRef?.current) return;

        const textElementWidth = textElementRef.current.offsetWidth;
        const containerWidth = containerRef.current.offsetWidth;

        if (containerWidth >= textElementWidth) {
            setFontSize(undefined);
            setIsFontFitting(true);
            return;
        }

        const reductionFactor = containerWidth / textElementWidth;
        const textFontSize = Number(getComputedStyle(textElementRef.current).fontSize.slice(0, -2));
        const newFontSize = textFontSize * reductionFactor;

        setIsFontFitting(true);
        setFontSize(newFontSize);

    }, [fontSize, isFontFitting, containerRef, textElementRef]);

    return fontSize;
};

export default useFontSizeFit;
